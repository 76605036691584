import { Button, Input } from "antd";
import { useState } from "react";

const Invitation = () => {
    const [code, setCode] = useState('');
    const [err, setErr] = useState('');
    const validateInput = () => {
        if (code !== process.env.REACT_APP_INVITATION_CODE) {
            setErr('邀请码无效，请输入正确的邀请码，或联系 @JICcapital/X 获取')
        } else {
            setErr('');
            window.open(process.env.REACT_APP_WHITEPAPER_LINK, '_blank');
        }
    }
    const onInputChange = (e) => {
        setCode(e.target.value);
    }

    return (
        <div className="invitation_container">
            <div className="invitation_input_container">
                <Input placeholder="输入邀请码" className="invitation_input" value={code} onChange={onInputChange} />
                <Button className="invitation_button" onClick={validateInput}>获取白皮书</Button>
            </div>
            {err !== '' && <p className="invitation_error">{err}</p>}
            {/* {showLink && <a href={process.env.REACT_APP_WHITEPAPER_LINK} target={'_blank'} rel="noreferrer">
                <img alt="whitepaper" className="invitation_link_img" src="/img/paper.png"></img>
            </a>} */}

        </div>
    );
}
export default Invitation;