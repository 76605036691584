import Invitation from "./invitation";

const Home = () => {
    return (
        <div className="home_container">
            <div className="home_opacity_bg" />
            <div className="home_content">
                <div className="home_heading">
                    <div className="home_heading_info">
                        <img className="home_heading_logo" alt="kanfang logo" src="/img/logo.png"></img>
                        <div className="home_heading_info_text">
                        <p className="home_heading_info_text_cn">无中介费的AI智能租房服务平台</p>
                        <p className="home_heading_info_text_en">AI Intelligent Rental Service Platform without Agency Fee</p>

                        </div>
                    </div>
                    <img className="home_heading_front_img" alt="livingroom" src="/img/bg_3.jpg"/>
                </div>
                <Invitation/>
            </div>
        </div >
    )
}

export default Home;