import './App.css';
import Home from './components/home';
import Nav from './components/nav';

function App() {
  return (
    <div className=' main_container'>
      <Nav />
      <Home />
    </div>
  );
}

export default App;
